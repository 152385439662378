import {
  INIT_ADVANCED_PARAMS,
  INIT_ADVANCED_PERIODS,
  INIT_REVENUE_PARAMS,
  INIT_REVENUE_PERIODS,
  INIT_TRANSACTION_PARAMS,
  INIT_TRANSACTION_PERIODS
} from '../../../models/funding.model';
import { IReduxFundingState } from './models';

const defaultState: IReduxFundingState = {
  allReportRevenuesLedger: [],
  revenuesLedgerTable: {
    resultCount: 0,
    hiddenColumns: [],
    params: INIT_REVENUE_PARAMS,
    filteredBy: {},
    availableFilters: {
      revenue_source_name: [],
      status: [],
      network: [],
      payment_status: []
    },
    allFilters: {
      revenue_source_name: [],
      status: [],
      network: [],
      payment_status: []
    },
    periods: INIT_REVENUE_PERIODS
  },
  allReportAdvancedLedger: [],
  advanceLedgerTable: {
    resultCount: 0,
    hiddenColumns: [],
    params: INIT_ADVANCED_PARAMS,
    filteredBy: {},
    availableFilters: {
      status: [],
      revenue_source: []
    },
    allFilters: {
      status: [],
      revenue_source: []
    },
    periods: INIT_ADVANCED_PERIODS
  },
  transactionsTable: {
    resultCount: 0,
    hiddenColumns: [],
    params: INIT_TRANSACTION_PARAMS,
    filteredBy: {},
    availableFilters: {
      transaction_type: [],
      source: [],
      currency: []
    },
    allFilters: {
      transaction_type: [],
      source: [],
      currency: []
    },
    periods: INIT_TRANSACTION_PERIODS
  }
};

export default defaultState;
