import { IReduxFundingActions, IReduxFundingState } from './models';
import defaultState from './store';
import { ReduxFundingType } from './types';

export default function fundingReducer(
  state: IReduxFundingState = defaultState,
  action: IReduxFundingActions
) {
  switch (action.type) {
    case ReduxFundingType.SET_ALL_REPORT_REVENUES_LEDGER:
      return { ...state, allReportRevenuesLedger: action.payload };
    case ReduxFundingType.SET_COUNT_RESULTS_REVENUES_LEDGER:
      return {
        ...state,
        revenuesLedgerTable: { ...state.revenuesLedgerTable, resultCount: action.payload }
      };
    case ReduxFundingType.SET_HIDDEN_COLUMNS_REVENUES_LEDGER:
      return {
        ...state,
        revenuesLedgerTable: { ...state.revenuesLedgerTable, hiddenColumns: action.payload }
      };
    case ReduxFundingType.SET_FILTERED_BY_REVENUES_LEDGER:
      return {
        ...state,
        revenuesLedgerTable: { ...state.revenuesLedgerTable, filteredBy: action.payload }
      };
    case ReduxFundingType.SET_REVENUE_PARAMS:
      return {
        ...state,
        revenuesLedgerTable: { ...state.revenuesLedgerTable, params: action.payload }
      };
    case ReduxFundingType.SET_AVAILABLE_FILTERS_REVENUES_LEDGER:
      return {
        ...state,
        revenuesLedgerTable: { ...state.revenuesLedgerTable, availableFilters: action.payload }
      };
    case ReduxFundingType.SET_ALL_FILTERS_REVENUES_LEDGER:
      return {
        ...state,
        revenuesLedgerTable: { ...state.revenuesLedgerTable, allFilters: action.payload }
      };
    case ReduxFundingType.SET_PERIODS_FILTER_REVENUES_LEDGER:
      return {
        ...state,
        revenuesLedgerTable: { ...state.revenuesLedgerTable, periods: action.payload }
      };
    case ReduxFundingType.SET_ALL_REPORT_ADVANCED_LEDGER:
      return { ...state, allReportAdvancedLedger: action.payload };

    case ReduxFundingType.SET_COUNT_RESULTS_ADVANCED_LEDGER:
      return {
        ...state,
        advanceLedgerTable: { ...state.advanceLedgerTable, resultCount: action.payload }
      };
    case ReduxFundingType.SET_HIDDEN_COLUMNS_ADVANCED_LEDGER:
      return {
        ...state,
        advanceLedgerTable: { ...state.advanceLedgerTable, hiddenColumns: action.payload }
      };
    case ReduxFundingType.SET_FILTERED_BY_ADVANCED_LEDGER:
      return {
        ...state,
        advanceLedgerTable: { ...state.advanceLedgerTable, filteredBy: action.payload }
      };
    case ReduxFundingType.SET_ADVANCED_PARAMS:
      return {
        ...state,
        advanceLedgerTable: { ...state.advanceLedgerTable, params: action.payload }
      };
    case ReduxFundingType.SET_AVAILABLE_FILTERS_ADVANCED_LEDGER:
      return {
        ...state,
        advanceLedgerTable: { ...state.advanceLedgerTable, availableFilters: action.payload }
      };
    case ReduxFundingType.SET_ALL_FILTERS_ADVANCED_LEDGER:
      return {
        ...state,
        advanceLedgerTable: { ...state.advanceLedgerTable, allFilters: action.payload }
      };
    case ReduxFundingType.SET_PERIODS_FILTER_ADVANCED_LEDGER:
      return {
        ...state,
        advanceLedgerTable: { ...state.advanceLedgerTable, periods: action.payload }
      };

    case ReduxFundingType.SET_COUNT_RESULTS_TRANSACTIONS_LEDGER:
      return {
        ...state,
        transactionsTable: { ...state.transactionsTable, resultCount: action.payload }
      };
    case ReduxFundingType.SET_HIDDEN_COLUMNS_TRANSACTIONS_LEDGER:
      return {
        ...state,
        transactionsTable: { ...state.transactionsTable, hiddenColumns: action.payload }
      };
    case ReduxFundingType.SET_FILTERED_BY_TRANSACTIONS_LEDGER:
      return {
        ...state,
        transactionsTable: { ...state.transactionsTable, filteredBy: action.payload }
      };
    case ReduxFundingType.SET_TRANSACTION_PARAMS:
      return {
        ...state,
        transactionsTable: { ...state.transactionsTable, params: action.payload }
      };
    case ReduxFundingType.SET_AVAILABLE_FILTERS_TRANSACTIONS_LEDGER:
      return {
        ...state,
        transactionsTable: { ...state.transactionsTable, availableFilters: action.payload }
      };
    case ReduxFundingType.SET_ALL_FILTERS_TRANSACTIONS_LEDGER:
      return {
        ...state,
        transactionsTable: { ...state.transactionsTable, allFilters: action.payload }
      };
    case ReduxFundingType.SET_PERIODS_FILTER_TRANSACTIONS_LEDGER:
      return {
        ...state,
        transactionsTable: { ...state.transactionsTable, periods: action.payload }
      };
    default:
      return state;
  }
}
