import React, { useState, useEffect } from 'react';
import Gleap from 'gleap';
import { useDispatch } from 'react-redux';
import Loading from '../containers/Loading';

const WithAuth = (WrappedComponent: any) => {
  const Authenticate = (props: any) => {
    const [authState, setAuthState] = useState('loading');
    const dispatch = useDispatch();

    useEffect(() => {
      // if no token exists redirect to login
      if (!localStorage.getItem('CUSTOMER_TOKEN') || !localStorage.getItem('EXPIRE_AT')) {
        localStorage.clear();
        Gleap.clearIdentity();
        dispatch({
          type: 'CLEAR_STATE'
        });
        window.location.href = '/login';
      }
      // if token exists but it has expired redirect to 403 page
      const token = localStorage.getItem('CUSTOMER_TOKEN');
      const expiry = localStorage.getItem('EXPIRE_AT') as string;
      const unix_now = new Date().getTime();
      if (token && +expiry < unix_now) {
        localStorage.clear();
        Gleap.clearIdentity();
        dispatch({
          type: 'CLEAR_STATE'
        });
        window.location.href = '/403';
      }
      if (token && +expiry > unix_now) {
        setAuthState('authed-session');
      }
    }, [dispatch]);

    return <>{authState === 'authed-session' ? <WrappedComponent {...props} /> : <Loading />}</>;
  };

  return Authenticate;
};

export default WithAuth;
