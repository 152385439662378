export enum ReduxFundingType {
  SET_ALL_REPORT_REVENUES_LEDGER = 'SET_ALL_REPORT_REVENUES_LEDGER',
  SET_COUNT_RESULTS_REVENUES_LEDGER = 'SET_COUNT_RESULTS_REVENUES_LEDGER',
  SET_HIDDEN_COLUMNS_REVENUES_LEDGER = 'SET_HIDDEN_COLUMNS_REVENUES_LEDGER',
  SET_REVENUE_PARAMS = 'SET_REVENUE_PARAMS',
  SET_FILTERED_BY_REVENUES_LEDGER = 'SET_FILTERED_BY_REVENUES_LEDGER',
  SET_ALL_FILTERS_REVENUES_LEDGER = 'SET_ALL_FILTERS_REVENUES_LEDGER',
  SET_AVAILABLE_FILTERS_REVENUES_LEDGER = 'SET_AVAILABLE_FILTERS_REVENUES_LEDGER',
  SET_PERIODS_FILTER_REVENUES_LEDGER = 'SET_PERIODS_FILTER_REVENUES_LEDGER',
  SET_ALL_REPORT_ADVANCED_LEDGER = 'SET_ALL_REPORT_ADVANCED_LEDGER',
  SET_COUNT_RESULTS_ADVANCED_LEDGER = 'SET_COUNT_RESULTS_ADVANCED_LEDGER',
  SET_HIDDEN_COLUMNS_ADVANCED_LEDGER = 'SET_HIDDEN_COLUMNS_ADVANCED_LEDGER',
  SET_ADVANCED_PARAMS = 'SET_ADVANCED_PARAMS',
  SET_FILTERED_BY_ADVANCED_LEDGER = 'SET_FILTERED_BY_ADVANCED_LEDGER',
  SET_ALL_FILTERS_ADVANCED_LEDGER = 'SET_ALL_FILTERS_ADVANCED_LEDGER',
  SET_AVAILABLE_FILTERS_ADVANCED_LEDGER = 'SET_AVAILABLE_FILTERS_ADVANCED_LEDGER',
  SET_PERIODS_FILTER_ADVANCED_LEDGER = 'SET_PERIODS_FILTER_ADVANCED_LEDGER',
  SET_COUNT_RESULTS_TRANSACTIONS_LEDGER = 'SET_COUNT_RESULTS_TRANSACTIONS_LEDGER',
  SET_HIDDEN_COLUMNS_TRANSACTIONS_LEDGER = 'SET_HIDDEN_COLUMNS_TRANSACTIONS_LEDGER',
  SET_TRANSACTION_PARAMS = 'SET_TRANSACTION_PARAMS',
  SET_FILTERED_BY_TRANSACTIONS_LEDGER = 'SET_FILTERED_BY_TRANSACTIONS_LEDGER',
  SET_ALL_FILTERS_TRANSACTIONS_LEDGER = 'SET_ALL_FILTERS_TRANSACTIONS_LEDGER',
  SET_AVAILABLE_FILTERS_TRANSACTIONS_LEDGER = 'SET_AVAILABLE_FILTERS_TRANSACTIONS_LEDGER',
  SET_PERIODS_FILTER_TRANSACTIONS_LEDGER = 'SET_PERIODS_FILTER_TRANSACTIONS_LEDGER'
}
