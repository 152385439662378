import { put, takeLatest, call } from 'redux-saga/effects';
import getApiCaller from '../../../getApiCaller';

const API_DATA = (action: any) => {
  const {
    customerId,
    currency = '',
    week = 'last_12_months',
    revenue = '',
    orderBy = 'event_date'
  } = action.payload;
  return getApiCaller(
    `reporting/CustomerTransactionLedgerGroupedCurrency/v3/?customer_id=${customerId}&week=${
      week ? week : 'last_12_months'
    }&ordering=${orderBy}&currency=${currency}&revenue_source_name=${revenue}`,
    'get',
    true
  ).then((response) => response);
};

const that = this;

export const GET_GROUPED_CURRENCY_SAGA = function* fetchUsers() {
  // eslint-disable-next-line
  yield takeLatest('GET_GROUPED_CURRENCY', function* (action: any) {
    yield put({ type: 'GET_GROUPED_CURRENCY_STARTED' });
    try {
      const DATA: typeof API_DATA = yield call(API_DATA.bind(that, action));
      // const DATA = yield call(API_DATA.bind(that, action));
      yield put({
        type: 'GET_GROUPED_CURRENCY_SUCCESS',
        payload: { status: 'success', data: DATA }
      });
    } catch (error: any) {
      if (error.toString() === 'TypeError: Network request failed') {
        yield put({
          type: 'GET_GROUPED_CURRENCY_NET_FAILED',
          payload: { status: 'failure', message: error.toString() }
        });
      } else {
        yield put({
          type: 'GET_GROUPED_CURRENCY_FAILED',
          payload: { status: 'failure', message: error }
        });
      }
    }
  });
};
